import React from 'react'
import { Container } from "../utils/Container"
import 'react-image-crop/dist/ReactCrop.css'
import { useImageProcessor } from '../utils/useImageProcessor'
import { RenderProcessedImageResult } from './RenderProcessedImageResult'
import { assertNever } from '../utils/utilFunctions'
import { useDropAndCropImage } from '../utils/useDropAndCropImage'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'

export const ScanExercise = () => {
    const imageProcessor = useImageProcessor(
        () => <h5>Please wait...</h5>,
        (result) => <RenderProcessedImageResult result={result} />,
        ({ imgURL, height, width }) => {
            return <>
                <hr />
                <div className='d-flex mg-t-20 flex-column align-items-center'>
                    <img
                        alt="Crop preview"
                        src={imgURL}
                        className='mw-100'
                        width={width}
                        height={height}
                        style={{ border: '1px solid #6c757d' }}
                    />
                </div>
            </>
        },
    )

    const dropzoneJsx = useDropAndCropImage(
        "Crop and solve exercise",
        "Crop and Solve",
        "square",
        async (
            croppedImageUrl,
            croppedImageWidth,
            croppedImageHeight,
            closeModal,
        ) => {
            // Don't await process. Just call it and close modal
            imageProcessor.process({
                imgURL: croppedImageUrl,
                width: croppedImageWidth,
                height: croppedImageHeight,
            })
            closeModal()
        },
        ({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
        }) => {
            return <section className="container">
                <div
                    {...getRootProps({ className: 'dropbox' })}
                    style={{ borderColor: `${isDragAccept ? "#00e676" : isDragReject ? "#ff1744" : "#1b84e7"}` }}
                >
                    <input {...getInputProps()} />
                    <p>{isDragActive ? "Pusti sliku zadatka..."
                        : "Klikni ili prevuci ovde sliku zadatka"}</p>
                </div>
            </section>
        },
    )

    let algorithm
    if (imageProcessor.algorithm === 'ai') {
        algorithm = "AI"
    } else if (imageProcessor.algorithm === 'vision') {
        algorithm = "Vision"
    } else {
        assertNever(imageProcessor.algorithm)
    }

    return <Container
        title={"Contribute"}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
    >
        {ENVIRONMENT_CALCULATED === 'dev' && false &&
            <div className='mg-b-20 mg-l-15'>
                Exercise will be solved by <span style={{ color: "#1b84e7" }}><b>{algorithm}</b></span> <span
                    className='span-btn'
                    onClick={() => imageProcessor.setAlgorithm(prev => {
                        if (prev === 'ai') {
                            return 'vision'
                        } else if (prev === 'vision') {
                            return 'ai'
                        } else {
                            assertNever(prev)
                        }
                    })}
                >Toggle</span>
            </div>
        }
        <div className='mg-t-20'>
            {dropzoneJsx}
        </div>
        {imageProcessor.render()}
    </Container>
}
