import { ZonedDateTime, ZoneId, Instant, DateTimeFormatter } from '@js-joda/core'
import '@js-joda/timezone'
import { COUNTRIES, EotPart } from './Common/Common'
import { useContext } from 'react'
import { AppContext } from '../contexts/AppContext'

// to fix timezone !!!!!!!!!!!
export function formatTimeFull(ts: number): string {
    const zdt = ZonedDateTime.ofInstant(Instant.ofEpochSecond(ts), ZoneId.of('Europe/Belgrade'))
    return `${zdt.dayOfMonth()}.${zdt.monthValue()}.${zdt.year()}. ${zdt.hour()}:${zdt.minute()}`
}

export function check(x: boolean, message?: string): asserts x {
    if (!x) {
        throw new Error(message)
    }
}

export function checkNotNull<T>(x: T | null | undefined, message?: string): T {
    if (x == null) {
        throw new Error(message)
    }
    return x
}

export function assertNever(x: never, message?: string): never {
    throw new Error(message)
}

export function nowSecondsUTC(): number {
    return ZonedDateTime.now(ZoneId.UTC).toEpochSecond()
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export function capitalizeFirstLetter(
    word: string,
    capitalizeFirstOtherSmall: boolean = true,
) {
    if (capitalizeFirstOtherSmall) {
        const wordLowercase = word.toLowerCase()
        return wordLowercase.charAt(0).toUpperCase() + wordLowercase.slice(1)
    } else {
        return word.charAt(0).toUpperCase() + word.slice(1)
    }
}

const NEW_LINE = "_newline"

// this function should exist everywhere: on client/native/backend
export const transformEotPartsToString = (
    parts: EotPart[],
): string | null => {
    const canBeTransformed = parts.length > 0
        && parts.every(it => it.content.type === 'text' && it.content.text.length > 0)

    if (!canBeTransformed) {
        return null
    }

    var result = ''

    const __ = [...parts]
        .sort((a, b) => a.order_number - b.order_number)
        .forEach((part, index) => {
            check(part.content.type === 'text', 'already checked up nSsy7uysa')
            if (index !== 0) {
                result += NEW_LINE
            }
            result += part.content.text
        })

    return result
}

// This doesn't work OK if there is an image in string.
// This should exist on clinet/native and maybe backend.
export const transformStringToEotParts = (
    data: string,
): EotPart[] => {
    const parts = data.split(NEW_LINE)

    return parts.map((p, index) => ({
        id: `${index}`,
        order_number: index,
        content: {
            type: "text",
            text: p,
        },
    }))
}

const ddMMYYYY_HHmmss_FORMATTER = DateTimeFormatter.ofPattern("dd.MM.yyyy. HH:mm:ss")
const ddMMYYYY_FORMATTER = DateTimeFormatter.ofPattern("dd.MM.yyyy.")

export const useDateFormatters = () => {
    const { gCountryId } = useContext(AppContext)

    const timezone = ZoneId.of(COUNTRIES.find(c => c.id === gCountryId)!.timezone)

    const ddMMYYYY_HHmmss = (utcSeconds: number): string => {
        return ddMMYYYY_HHmmss_FORMATTER.format(
            ZonedDateTime.ofInstant(
                Instant.ofEpochSecond(utcSeconds),
                timezone,
            )
        )
    }

    const ddMMYYYY = (utcSeconds: number): string => {
        return ddMMYYYY_FORMATTER.format(
            ZonedDateTime.ofInstant(
                Instant.ofEpochSecond(utcSeconds),
                timezone,
            )
        )
    }

    return {
        ddMMYYYY_HHmmss,
        ddMMYYYY,
    }
}
