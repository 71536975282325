import React, { useContext, useState } from 'react'
import { assertNever } from "../utils/utilFunctions"
import { PlainTextPart } from "./PlainTextPart"
import { LatexPart } from "./LatexPart"
import { AddFieldCard } from "./AddFieldCard"
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { SaveExerciseModal, SaveExerciseModalParams } from './SaveExerciseModal'
import { FormGroup, Input } from 'reactstrap'
import { YoutubeVideo } from '../utils/YoutubeVideo'
import { queryServer } from '../utils/queryServer'
import { SaveTheoryModal, SaveTheoryModalParams } from './SaveTheoryModal'
import * as _ from "lodash"
import { useSearchParams } from "react-router-dom"
import { useModal } from '../utils/useModal'
import { EotPart, VideoMetadata, ExerciseOrTheory } from '../utils/Common/Common'
import { uploadFile } from '../utils/uploadFile'
import { AppContext } from '../contexts/AppContext'
import { nanoid } from 'nanoid'

type Props = {
    solve: ExerciseOrTheory
}

export type EotPartAndRendered = EotPart & {
    renderRaw: boolean
}

type VideoMetadataRendered = VideoMetadata & {
    renderRaw: boolean
}

type SolveWithEdit = {
    solve: 'exercise'
    edit: RequestResponseTypes["getExerciseWithMetadata"]["response"] | null
} | {
    solve: 'theory'
    edit: RequestResponseTypes["getTheoryWithMetadata"]["response"] | null
}

const SolveEotX = ({
    solveWithEdit,
    showExerciseModal,
    showTheoryModal,
}: {
    solveWithEdit: SolveWithEdit
    showTheoryModal: (params: SaveTheoryModalParams) => void
    showExerciseModal: (params: SaveExerciseModalParams) => void
}) => {
    const { gHigherAuthorityLevel } = useContext(AppContext)

    const calculateInitialEotText = (): EotPartAndRendered[] => {
        if (solveWithEdit.solve === 'exercise' && solveWithEdit.edit != null) {
            return solveWithEdit.edit.exerciseMetadata.exercise_text_public.map(it => ({
                ...it,
                renderRaw: true,
            }))
        } else if (solveWithEdit.solve === 'theory' && solveWithEdit.edit != null) {
            return solveWithEdit.edit.theoryMetadata.theory_text.map(it => ({
                ...it,
                renderRaw: true,
            }))
        } else {
            return []
        }
    }

    const calculateInitialEotSolution = (): EotPartAndRendered[] => {
        if (solveWithEdit.solve === 'exercise' && solveWithEdit.edit != null) {
            return solveWithEdit.edit.textSolution.map(it => ({
                ...it,
                renderRaw: true,
            }))
        } else if (solveWithEdit.solve === 'theory' && solveWithEdit.edit != null) {
            return solveWithEdit.edit.textSolution.map(it => ({
                ...it,
                renderRaw: true,
            }))
        } else {
            return []
        }
    }

    const calculateInitialEotVideoMetadata = (): VideoMetadataRendered => {
        if (solveWithEdit.edit != null && solveWithEdit.edit.video_solution != null) {
            return {
                ...solveWithEdit.edit.video_solution.videoMetadata,
                renderRaw: true,
            }
        } else {
            return {
                type: 'youtube',
                videoId: '',
                renderRaw: true,
            }
        }
    }

    const [eotText, setEotText] = React.useState<EotPartAndRendered[]>(calculateInitialEotText())
    const [eotSolution, setEotSolution] = React.useState<EotPartAndRendered[]>(calculateInitialEotSolution())
    const [videoMetadata, setVideoMetadata] = React.useState<VideoMetadataRendered>(calculateInitialEotVideoMetadata())

    const [editOrTemplate, setEditOrTemplate] = useState<'edit' | 'template'>('edit')

    const deletePartId = React.useRef<string | null>(null)
    const deleteTimerRef = React.useRef<NodeJS.Timeout>()
    const deletePartHandler = (partId: string, partFrom: 'text' | 'solution') => {
        if (deleteTimerRef.current) {
            clearTimeout(deleteTimerRef.current)
        }

        if (deletePartId.current === partId) {
            if (partFrom === 'text') {
                setEotText(prev => {
                    return prev.filter(it => it.id !== partId)
                })
            } else if (partFrom === 'solution') {
                setEotSolution(prev => {
                    return prev.filter(it => it.id !== partId)
                })
            } else {
                assertNever(partFrom)
            }

            deletePartId.current = null
        } else {
            deletePartId.current = partId
            deleteTimerRef.current = setTimeout(
                () => deletePartId.current = null,
                800,
            )
        }
    }

    const toggleAllParts = () => {
        let newRenderRaw: boolean
        if (eotText.length > 0) {
            newRenderRaw = !eotText[0].renderRaw
        } else if (eotSolution.length > 0) {
            newRenderRaw = !eotSolution[0].renderRaw
        } else {
            newRenderRaw = !videoMetadata.renderRaw
        }

        setEotText(eotText.map(it => ({ ...it, renderRaw: newRenderRaw })))
        setEotSolution(eotSolution.map(it => ({ ...it, renderRaw: newRenderRaw })))
        setVideoMetadata(prev => ({ ...prev, renderRaw: newRenderRaw }))
    }

    const renderVideoSolution = () => {
        if (videoMetadata.renderRaw) {
            return <FormGroup>
                <Input
                    bsSize='lg'
                    type="textarea"
                    placeholder='enter youtube video id here...'
                    value={videoMetadata.videoId.trim()}
                    onChange={(e) => setVideoMetadata(prev => ({
                        ...prev,
                        videoId: e.target.value.trim(),
                    }))}
                />
            </FormGroup>
        } else {
            if (videoMetadata.videoId.length > 0) {
                return <YoutubeVideo videoId={videoMetadata.videoId} />
            } else {
                return <h5>No video solution. (This message is only shown while solving eot)</h5>
            }
        }
    }

    const togglePartHandler = (id: string, type: 'text' | 'solution') => {
        if (type === 'text') {
            setEotText(eotText.map(it => ({
                ...it,
                renderRaw: it.id === id ? !it.renderRaw : it.renderRaw,
            })))
        } else if (type === 'solution') {
            setEotSolution(eotSolution.map(it => ({
                ...it,
                renderRaw: it.id === id ? !it.renderRaw : it.renderRaw,
            })))
        } else {
            assertNever(type)
        }
    }

    const openModal = () => {
        // these checks are not very strict, they just help a little
        if (eotText.length === 0) {
            console.log('no eotText')
        } else if (eotSolution.length === 0 && videoMetadata.videoId.length === 0) {
            console.log('there is neither eotSolution nor eotVideo')
        } else if (JSON.stringify(eotText).length > 2600) {
            console.log(`Eot text too long: ${JSON.stringify(eotText).length}`)
        } else {
            // everything should be OK. Show the modal:
            if (solveWithEdit.solve === 'exercise') {
                showExerciseModal({
                    eotText: eotText,
                    eotSolution,
                    videoSolution: videoMetadata.videoId.length > 0 ? videoMetadata : null,
                    editExercise: editOrTemplate === 'edit' ? solveWithEdit.edit : null,
                })
            } else {
                showTheoryModal({
                    eotText: eotText,
                    eotSolution,
                    videoSolution: videoMetadata.videoId.length > 0 ? videoMetadata : null,
                    editTheory: editOrTemplate === 'edit' ? solveWithEdit.edit : null,
                })
            }
        }
    }

    const renderTitle = () => {
        if (solveWithEdit.solve === 'exercise') {
            return "Solve exercise"
        } else {
            return "Solve theory"
        }
    }

    const updateExercisePhotoUrl = async (exerciseId: string, photoUrl: string | null) => {
        await queryServer(
            'updateExercisePhotoUrl',
            {
                exerciseId,
                photoUrl,
            },
        )

        location.reload()
    }

    const toggleMarkedForTraining = async (exerciseId: string, okForTraining: boolean) => {
        await queryServer(
            'updateExerciseMarkForTraining',
            {
                exerciseId,
                okForTraining,
            },
        )

        location.reload()
    }

    const renderExercisePhotoWithEdit = () => {
        if (
            gHigherAuthorityLevel === 10
            && solveWithEdit.solve === 'exercise'
            && solveWithEdit.edit?.exerciseMetadata.exercise_text_photo_url != null
        ) {
            return <div style={{
                borderBottom: "1px solid blue",
                marginBottom: "40px",
                paddingBottom: "40px",
            }}>
                <img
                    className='img-in-eot-solution'
                    src={solveWithEdit.edit.exerciseMetadata.exercise_text_photo_url}
                    alt="No, or bad image..."
                />
                <div className='mg-t-20'>
                    <span
                        className='span-btn'
                        style={{ color: "red" }}
                        onClick={() => {
                            updateExercisePhotoUrl(solveWithEdit.edit!.exerciseMetadata.id, null)
                        }}
                    >
                        Delete photo
                    </span>
                </div>
            </div>
        } else {
            return null
        }
    }

    const renderAddPhoto = () => {
        if (
            gHigherAuthorityLevel === 10
            && solveWithEdit.solve === 'exercise'
            && solveWithEdit.edit?.exerciseMetadata != null
            && solveWithEdit.edit.exerciseMetadata.exercise_text_photo_url == null
        ) {
            return <div style={{
                borderBottom: "1px solid blue",
                marginBottom: "40px",
                paddingBottom: "40px",
            }}>
                <div className='mg-t-20'>
                    Upload exercise text photo
                    <input
                        id="filePicker"
                        style={{}}
                        type={"file"}
                        onChange={async (e) => {
                            if (e.target.files) {
                                const uploadResult = await uploadFile(e.target.files[0], "exercise-text-images")

                                if (uploadResult != null) {
                                    await updateExercisePhotoUrl(
                                        solveWithEdit.edit!.exerciseMetadata.id,
                                        uploadResult.fileUrl,
                                    )
                                }
                            }
                        }}
                    />
                </div>
            </div>
        } else {
            return null
        }
    }

    const renderMarkForTraining = () => {
        if (
            gHigherAuthorityLevel === 10
            && solveWithEdit.solve === 'exercise'
            && solveWithEdit.edit?.exerciseMetadata != null
        ) {
            return <div style={{
                borderBottom: "1px solid blue",
                marginBottom: "40px",
                paddingBottom: "40px",
            }}>
                <h2>
                    Marked for training: {solveWithEdit.edit!.exerciseMetadata.marked_for_training_at == null ? <span style={{ color: "red" }}>NO</span> : <span style={{ color: "green" }}>YES</span>}
                </h2>
                {solveWithEdit.edit.exerciseMetadata.exercise_text_photo_url != null && <span
                    className='span-btn'
                    onClick={() => {
                        toggleMarkedForTraining(
                            solveWithEdit.edit!.exerciseMetadata.id,
                            solveWithEdit.edit!.exerciseMetadata.marked_for_training_at == null ? true : false,
                        )
                    }}
                >
                    Toggle
                </span>}
            </div>
        } else {
            return null
        }
    }

    return <div className='contributors-exercise-page'>
        <h3>{renderTitle()}</h3>
        {renderExercisePhotoWithEdit()}
        {renderAddPhoto()}
        {renderMarkForTraining()}
        <div className="contributors-page-heder">
            {solveWithEdit.edit != null && <button
                className="btn btn-warning btn-teal mg-b-10"
                style={{ backgroundColor: 'green' }}
                onClick={() => {
                    if (editOrTemplate === 'template') {
                        location.reload()
                    } else {
                        setEotText(prev => prev.map(it => ({
                            ...it,
                            id: nanoid(),
                        })))
                        setEotSolution(prev => prev.map(it => ({
                            ...it,
                            id: nanoid(),
                        })))
                        setEditOrTemplate('template')
                    }
                }}
            >
                {editOrTemplate === 'edit' ? "Edit mode" : "Template mode"}
            </button>}
            <button
                className="btn btn-warning btn-teal mg-b-10"
                style={{ backgroundColor: solveWithEdit.solve === 'exercise' ? '#9900e6' : '#ff8400' }}
                onClick={toggleAllParts}
            >
                Toggle all parts
            </button>
            <button
                className="btn btn-warning btn-teal mg-b-10"
                style={{ backgroundColor: solveWithEdit.solve === 'exercise' ? '#0066cc' : '#b3b300' }}
                onClick={openModal}
            >
                Next
            </button>
        </div>
        <div className="row exercise-page">
            {_.sortBy(eotText, (eotPartAndRendered) => eotPartAndRendered.order_number)
                .map(it => {
                    if (it.renderRaw) {
                        return <PlainTextPart
                            key={it.id}
                            togglePart={() => togglePartHandler(it.id, 'text')}
                            deletePart={() => deletePartHandler(it.id, 'text')}
                            eotPart={it}
                            setEotParts={setEotText}
                        />
                    } else {
                        return <LatexPart
                            key={it.id}
                            eotPart={it}
                            toggle={() => togglePartHandler(it.id, 'text')}
                            deletePart={() => deletePartHandler(it.id, 'text')}
                        />
                    }
                })}

            <AddFieldCard setNewParts={setEotText} />

            <div className="text-solution-border">
                The solution goes below
            </div>

            {_.sortBy(eotSolution, (eotPartAndRendered) => eotPartAndRendered.order_number)
                .map(it => {
                    if (it.renderRaw) {
                        return <PlainTextPart
                            key={it.id}
                            togglePart={() => togglePartHandler(it.id, 'solution')}
                            deletePart={() => deletePartHandler(it.id, 'solution')}
                            eotPart={it}
                            setEotParts={setEotSolution}
                        />
                    } else {
                        return <LatexPart
                            key={it.id}
                            eotPart={it}
                            toggle={() => togglePartHandler(it.id, 'solution')}
                            deletePart={() => deletePartHandler(it.id, 'solution')}
                        />
                    }
                })}

            <AddFieldCard setNewParts={setEotSolution} />

            <div className="text-solution-border">
                Video solution goes down
            </div>

            {renderVideoSolution()}
        </div>
    </div>
}

export const SolveEot = ({ solve }: Props) => {
    const [solveWithEdit, setSolveWithEdit] = React.useState<SolveWithEdit>()

    const [searchParams] = useSearchParams()

    React.useEffect(() => {
        calculateSolveWithEdit()
    }, [])

    const saveTheoryModal = useModal(SaveTheoryModal)
    const saveExerciseModal = useModal(SaveExerciseModal)

    const calculateSolveWithEdit = async () => {
        const editId = searchParams.get("edit")

        if (solve === 'exercise' && editId != null) {
            const exercise = await readExerciseToEdit(editId)
            setSolveWithEdit({
                solve,
                edit: exercise,
            })
        } else if (solve === 'theory' && editId != null) {
            const theory = await readTheoryToEdit(editId)
            setSolveWithEdit({
                solve,
                edit: theory,
            })
        } else {
            setSolveWithEdit({
                solve,
                edit: null,
            })
        }
    }

    const readExerciseToEdit = async (exerciseId: string) => {
        const response = await queryServer(
            'getExerciseWithMetadata',
            {
                exerciseId,
            },
        )

        return response
    }

    const readTheoryToEdit = async (theoryId: string) => {
        const response = await queryServer(
            'getTheoryWithMetadata',
            {
                theoryId,
            },
        )

        return response
    }

    if (solveWithEdit == null) {
        return <h2>Please wait...</h2>
    }

    return <>
        <SolveEotX
            solveWithEdit={solveWithEdit}
            showExerciseModal={saveExerciseModal.show}
            showTheoryModal={saveTheoryModal.show}
        />
        {saveExerciseModal.render()}
        {saveTheoryModal.render()}
    </>
}
