import React from 'react'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { ModalContentProps } from "../utils/useModal"
import { useDropAndCropImage } from '../utils/useDropAndCropImage'
import { getProfileImageURL, onProfileImageError } from "../Header/Header"

type Props = {
    profilePhoto: string | null
    userId: string
}

export function ProfilePhotoModal({
    params,
    closeModal,
}: ModalContentProps<Props>) {
    const { profilePhoto, userId } = params

    const [image, setImage] = React.useState<{
        imgURL: string
        width: number
        height: number
    }>()

    const dropzoneJsx = useDropAndCropImage(
        "Crop profile photo",
        "Save",
        "circle",
        async (
            croppedImageUrl,
            croppedImageWidth,
            croppedImageHeight,
            closeModal,
        ) => {
            if (croppedImageWidth != null && croppedImageHeight != null) {
                setImage({
                    imgURL: croppedImageUrl,
                    width: croppedImageWidth,
                    height: croppedImageHeight,
                })
            }

            closeModal()
        },
        ({
            getRootProps,
            getInputProps,
        }) => {
            return <Button style={{ position: 'relative' }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <span>
                    <i className="fa fa-camera" aria-hidden="true"
                        style={{ fontSize: '20px', paddingRight: '4px' }}>
                    </i>
                    Add photo
                </span>
            </Button>
        },
    )

    return <div className='responsive-modal-box' style={{ width: "480px" }}>
        <ModalHeader toggle={closeModal}>
            Profile photo
        </ModalHeader>
        <ModalBody style={{ alignItems: 'center' }}>
            {image?.imgURL != null || profilePhoto != null ?
                <img
                    alt="Crop preview"
                    src={image?.imgURL}
                    style={{
                        height: '200px',
                        width: '200px',
                        borderRadius: '50%'
                    }}
                /> :
                <img src={getProfileImageURL(userId)} onError={onProfileImageError} alt="profile image" className='dropbox-profile-image' />
            }
        </ModalBody>
        <ModalFooter>
            {dropzoneJsx}
            <Button color="danger">Delete</Button>
            <Button color="primary">Save</Button>
        </ModalFooter>
    </div>
}