import React from 'react'
import { assertNever, transformStringToEotParts } from "../utils/utilFunctions"
import { ProcessImageResult } from '../utils/useImageProcessor'
import { Eot } from '../ExercisePage/Eot'

export const RenderProcessedImageResult = ({
    result,
}: { result: ProcessImageResult }) => {
    let content
    if (result.status === "our_solution") {
        content = <>
            <div className='mg-t-10'>
                <Eot
                    kind="exercise"
                    eotId={result.exercise.id}
                    textSolution={result.exercise.textSolution}
                    text_public={result.exercise.exercise_text_public}
                    video_solution={result.exercise.video_solution?.videoMetadata ?? null}
                    subjectId={result.exercise.subjectId}
                    initialyQueriedComments={undefined}
                    hideEdit={true}
                    hideSaveButton={true}
                    hideText={true}
                    showNeedToBuyScanPlan={!result.exercise.isCompleteSolution}
                />
            </div>
            <hr />
            <div
                className='mg-t-20'
                style={{ fontSize: "small", color: "#BEBEBE", textAlign: "center" }}
            >
                Avg match probability: {Math.round(result.avgMatch * 100)}%
                &nbsp; Exercise match probability - {Math.round(result.exerciseMatch * 100)}%
                &nbsp; Ocr match probability - {Math.round(result.ocrMatch * 100)}%
            </div>
        </>
    } else if (result.status === "ai_solution") {
        content = <>
            <div className='mg-t-10 mg-b-50'>
                <Eot
                    kind="exercise"
                    eotId={"unused_exercise_id"}
                    textSolution={transformStringToEotParts(result.solution)}
                    text_public={[]}
                    video_solution={null}
                    subjectId={"unused subj idd"}
                    initialyQueriedComments={undefined}
                    hideEdit={true}
                    hideSaveButton={true}
                    hideText={true}
                />
            </div>
            <hr />
            <div
                className='mg-t-50'
                style={{ fontSize: "small", color: "#BEBEBE", textAlign: "center" }}
            >
                {result.solution}
            </div>
        </>
    } else if (result.status === "error") {
        content = <h2>Error: {result.message}</h2>
    } else {
        assertNever(result)
    }

    return <div>
        {content}
    </div>
}
