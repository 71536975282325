import React from 'react'
import { useNavigate } from 'react-router-dom'
import { nowSecondsUTC, useDateFormatters } from '../utils/utilFunctions'

type Props = {
    assignedScanTracker: {
        validUntilTs: number
        includedNScans: number
        usedNScans: number
    } | null
}

export function ScanTracker({ assignedScanTracker }: Props) {
    const navigate = useNavigate()
    const { ddMMYYYY } = useDateFormatters()

    const nowTs = nowSecondsUTC()

    const hasActivePlan = assignedScanTracker != null && assignedScanTracker.validUntilTs > nowTs

    let content
    if (hasActivePlan) {
        content = <>
            <h2 className="tx-primary mg-b-20">Scan tracker</h2>
            <div className="row row-xs">
                <div
                    className="col-4"
                    style={{ fontSize: '16px', textAlign: 'left' }}
                >
                    Scans left:
                </div>
                <div className="col-8" style={{ fontSize: '24px' }}>
                    {assignedScanTracker.includedNScans - assignedScanTracker.usedNScans} / {assignedScanTracker.includedNScans}
                </div>
            </div>
            <hr />
            <div className="row row-xs">
                <div className="col-4 small" style={{ fontSize: '16px', textAlign: 'left' }}>
                    Valid until:
                </div>
                <div
                    className="col-8 mb-2"
                    style={{ fontSize: '24px', maxWidth: '100%' }}
                >
                    {ddMMYYYY(assignedScanTracker.validUntilTs)}
                </div>
            </div>
            <hr />
            <div className="row row-xs">
                <div
                    className="btn-primary tx-white"
                    style={{ cursor: 'pointer', fontSize: '16px', padding: '10px 14px' }}
                    onClick={() => navigate('/pricing')}
                >
                    Extend a plan
                </div>
            </div>
        </>
    } else {
        content = <div className="row row-xs flex-column align-items-start">
            <h2 className="tx-primary mg-b-20">Scan tracker</h2>
            <div style={{ marginBottom: '20px', fontSize: '14px', color: 'black' }}>
                You don't have an active plan.
            </div>
            <div className="row row-xs">
                <div
                    className="btn-primary tx-white"
                    style={{ cursor: 'pointer', fontSize: '16px', padding: '10px 14px' }}
                    onClick={() => navigate('/pricing')}
                >
                    Buy a plan
                </div>
            </div>
        </div>
    }

    return <div className="col-lg-4 mg-t-20 mg-lg-t-0">
        <div className="card card-connection">
            {content}
        </div>
    </div>
}
