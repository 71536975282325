import React from 'react'
import { Container } from "../utils/Container"
import { AppContext } from "../contexts/AppContext"
import { useNavigate } from 'react-router-dom'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'

export const Contribute = () => {
    const {
        gAllCommentsNotification,
        gHigherAuthorityLevel,
        gStatus,
        gCountryId,
    } = React.useContext(AppContext)

    const navigate = useNavigate()

    const renderOption = (
        title: string,
        navigateTo: string,
        condition: boolean,
        hasNotification?: boolean,
    ) => {
        return condition && <div
            onClick={() => {
                navigate(`${navigateTo}`)
            }}
        >
            <h2 className={`h-btn ${hasNotification ? 'h-has-notificationx' : ''}`}>
                {title}
            </h2>
        </div>
    }

    return <Container
        title={"Contribute"}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: false,
        }}
    >
        {ENVIRONMENT_CALCULATED === "dev" && <div className='mg-b-10'>
            <span
                className="mg-10"
                aria-expanded="true"
                style={{
                    color: gCountryId === 'eng-dev' || gCountryId === 'srb-dev' ? "green" : "red",
                    fontWeight: gCountryId === 'eng-dev' || gCountryId === 'srb-dev' ? undefined : "bold",
                }}
            >
                {gCountryId !== 'eng-dev' && gCountryId !== 'srb-dev' ? "NOT" : ""} safe to solve EOTs as contributor
            </span>
        </div>}
        {renderOption(
            'Check all comments',
            '/contribute/all-comments-check',
            ENVIRONMENT_CALCULATED === "dev" && gStatus !== 'basic',
            gAllCommentsNotification,
        )}

        {renderOption(
            'Solve exercise',
            '/contribute/solveExercise',
            gStatus !== 'basic',
        )}

        {renderOption(
            'Solve theory',
            '/contribute/solveTheory',
            gStatus !== 'basic',
        )}

        {renderOption(
            'My solvings',
            '/contribute/mySolvings',
            true,
        )}

        {renderOption(
            'Add ISBN',
            '/contribute/addIsbn',
            gHigherAuthorityLevel === 10 && gStatus !== 'basic',
        )}

        {renderOption(
            'Organize subjects',
            '/contribute/subjectsAuthority',
            gHigherAuthorityLevel === 10 && gStatus !== 'basic',
        )}

        {renderOption(
            'Manage scan optimizations',
            '/contribute/scan-optimization',
            gHigherAuthorityLevel === 10 && gStatus !== 'basic',
        )}

        {renderOption(
            'User Scan Tracker',
            '/contribute/user-scan-tracker',
            gHigherAuthorityLevel === 10 && gStatus !== 'basic',
        )}

        {ENVIRONMENT_CALCULATED === 'dev' && renderOption(
            'Manage training exercises',
            '/contribute/training-exercises',
            gHigherAuthorityLevel === 10 && gStatus !== 'basic',
        )}
    </Container>
}
