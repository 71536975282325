import { DateTimeFormatter, Instant, ZonedDateTime, ZoneId } from '@js-joda/core'
import _ from 'lodash'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SaveInCollection_Modal } from '../ExercisePage/SaveInCollection_Modal'
import { COUNTRIES, ExerciseInformationsForGrade, TestInGrade, EotPart, randomIntFromInterval } from '../utils/Common/Common'
import { EotTextInList } from '../utils/EotTextInList'
import { Loader } from '../utils/Loader'
import { useModal } from '../utils/useModal'
import { check, checkNotNull, delay, nowSecondsUTC } from '../utils/utilFunctions'
import { LAST_FILTERS } from './LastFilters'
import '@js-joda/timezone'
import { AppContext } from '../contexts/AppContext'

type Props = {
    exercise: ExerciseInformationsForGrade
    tests: TestInGrade[]
}

const DTF = DateTimeFormatter.ofPattern('dd.MM.yyyy HH:mm')

let scrollActivated = false

export const FindExercise_Tests = ({
    exercise,
    tests,
}: Props) => {
    const navigate = useNavigate()
    const saveInCollectionModal = useModal(SaveInCollection_Modal)

    const { gCountryId } = useContext(AppContext)

    // dummy will be used to force react rerender so it scroll down when
    // test is created for the first time. Hacki but OK for now. Should be fixed
    const [dummy, setDummy] = React.useState(0)

    const elementRef = React.useRef<HTMLDivElement>(null)

    const scrollToElement = () => {
        if (scrollActivated) {
            elementRef.current
                ?.scrollIntoView({ behavior: 'smooth', block: 'start' })

            scrollActivated = false
        }
    }

    React.useEffect(() => {
        scrollToElement()
    }, [elementRef.current != null])

    const [generatedTest, setGeneratedTest] = React.useState<{
        testId: string
        generatedAt: string
        generatedExercises: {
            id: string
            publicText: EotPart[]
            additionalKeywords: string
            difficulty: number
            inAreaId: string
            inAreaName: string
            avgOrderNum: number
            lastUpdatedTs: number
        }[]
    } | undefined | string>(() => {
        if (LAST_FILTERS.lastGeneratedExercisesTest != null) {
            const {
                exerciseIds: lastGeneratedExerciseIds,
                testId: lastGeneratedTestId,
                generatedAt: lastGeneratedAt,
            } = LAST_FILTERS.lastGeneratedExercisesTest

            // check if it is OK to use LAST_FILTERS.lastGeneratedExercisesTest:
            // 1. check if testId exists
            // 2. Check if every generated exercise ID exists

            // 1:
            const test = tests.find(it => it.testId === lastGeneratedTestId)
            if (test == null) {
                LAST_FILTERS.lastGeneratedExercisesTest = undefined
                return undefined
            }

            // 2:
            const allExercisesMap = new Map(exercise.exercises.map(it => [it.id, it]))
            for (const exerciseId of lastGeneratedExerciseIds) {
                if (!allExercisesMap.has(exerciseId)) {
                    LAST_FILTERS.lastGeneratedExercisesTest = undefined
                    return undefined
                }
            }

            const areaNamesById = new Map(exercise.areaOrganization.map(it => [it.areaId, it.area_name]))

            return {
                generatedExercises: lastGeneratedExerciseIds.map(exerciseId => {
                    const exercise = checkNotNull(allExercisesMap.get(exerciseId), "dQau8u8u")
                    const areaName = checkNotNull(areaNamesById.get(exercise.inAreaId), "wehgs555")
                    return {
                        ...exercise,
                        inAreaName: areaName,
                    }
                }),
                generatedAt: lastGeneratedAt,
                testId: lastGeneratedTestId,
            }
        } else {
            return undefined
        }
    })

    const generateTest = async (test: TestInGrade) => {
        if (typeof generatedTest === "string") {
            return
        }

        setGeneratedTest(test.testId)

        await delay(600)

        scrollActivated = true

        // this will be used to prevent adding 2 same exercises in test
        const generatedMap = new Set<string>()

        const areaNamesById = new Map(exercise.areaOrganization.map(it => [it.areaId, it.area_name]))

        const generatedExercises = _.sortBy(test.exercises, (exercise) => exercise.orderNumber)
            .map(e => {
                const random = randomIntFromInterval(0, 100)

                let chosenRule: { areaId: string, difficulty: Set<number> } | undefined = undefined
                let sum = 0
                e.rules.forEach(rule => {
                    sum += rule.probability

                    if (chosenRule == null && random <= sum) {
                        chosenRule = {
                            areaId: rule.gradeAreaId,
                            difficulty: rule.difficulty === "easy" ? new Set([1, 2, 3]) :
                                (rule.difficulty === "medium" ? new Set([4, 5, 6, 7]) : new Set([8, 9, 10])),
                        }
                    }
                })

                check(chosenRule != null, "Fue4")

                const possibleMatches = exercise.exercises
                    .filter(it => chosenRule!.difficulty.has(it.difficulty)
                        && it.inAreaId === chosenRule!.areaId
                        && !generatedMap.has(it.id))

                if (possibleMatches.length === 0) {
                    console.log("hmmm????",)
                    return null
                } else {
                    const randomExerciseIndex = randomIntFromInterval(0, possibleMatches.length - 1)
                    const g = possibleMatches[randomExerciseIndex]
                    generatedMap.add(g.id)
                    return {
                        ...g,
                        inAreaName: checkNotNull(areaNamesById.get(g.inAreaId), "wq6ifk"),
                    }
                }
            }).filter(it => it != null) as {
                id: string
                publicText: EotPart[]
                additionalKeywords: string
                difficulty: number
                inAreaId: string
                inAreaName: string
                avgOrderNum: number
                lastUpdatedTs: number
            }[]

        const generatedAt = DTF.format(
            ZonedDateTime.ofInstant(
                Instant.ofEpochSecond(nowSecondsUTC()),
                ZoneId.of(COUNTRIES.find(c => c.id === gCountryId)!.timezone)
            )
        )

        setGeneratedTest({
            generatedExercises,
            generatedAt: generatedAt,
            testId: test.testId,
        })

        LAST_FILTERS.lastGeneratedExercisesTest = {
            exerciseIds: generatedExercises.map(it => it.id),
            generatedAt: generatedAt,
            testId: test.testId,
        }

        setTimeout(() => {
            setDummy(prev => prev + 1)
        }, 0)
    }

    const renderGeneratedTest = () => {
        if (generatedTest == null || typeof generatedTest === "string") {
            return null
        } else {
            const test = checkNotNull(tests.find(it => it.testId === generatedTest.testId), "rsadgH7")

            return <div className='mg-t-40 pd-l-20 pd-b-10 pd-t-10' style={{
                borderLeft: "2px solid #1b84e7",
            }}>
                <div ref={elementRef}></div>

                <div className='mg-b-45'>
                    <div>
                        Test generisan {generatedTest.generatedAt}
                    </div>
                    <h2 style={{ color: "#333333" }} className="mg-t-15">
                        {test.title}
                    </h2>
                    <h5 style={{ color: "#5c5c5c" }}>
                        {test.description}
                    </h5>
                    <div className="mg-t-25">
                        <span
                            onClick={() => saveInCollectionModal.show({
                                toSave: {
                                    kind: "test",
                                    exerciseIds: generatedTest.generatedExercises.map(it => it.id),
                                    testTitle: test.title,
                                    testDescription: `Test created ${generatedTest.generatedAt}`,
                                }
                            })}
                            style={{
                                background: "#53c68c",
                                borderRadius: "3px",
                                padding: "5px 10px 5px 10px",
                                cursor: "pointer",
                                color: "white",
                            }}
                        >
                            Sačuvaj generisani test <i className="mg-l-5 icon ion-heart"></i>
                        </span>
                    </div>
                </div>

                {generatedTest.generatedExercises.map((exercise, index) => {
                    return <div
                        key={exercise.id}
                    >
                        <EotTextInList
                            eotText={exercise.publicText}
                            difficulty={undefined}
                            area={undefined}
                            exerciseOrderNumberInList={index + 1}
                            avgOrderNumInBook={undefined}
                            onClick={() => navigate(`/exercise/${exercise.id}`)}
                            key={exercise.id}
                        />
                    </div>
                })}
            </div>
        }
    }

    return <div>
        <ul className='mg-b-25 mg-t-30' style={{ marginLeft: 0, paddingLeft: 14 }}>
            <li>Klikom na dugme za generisanje testa uvek ćeš dobiti drugačiji test</li>
            <li>Ukoliko želiš da sačuvas generisani test, to možeš uraditi klikom na dugme "Sačuvaj generisani test"</li>
        </ul>
        {_.sortBy(tests, test => test.orderNum).map((test, index) => {
            return <div
                key={test.testId}
                style={{
                    borderTop: index === 0 ? "1px solid #d4d4d4" : undefined,
                    borderBottom: "1px solid #d4d4d4",
                    padding: "8px",
                    paddingBottom: "14px",
                    paddingTop: "14px",
                }}
            >
                <h4 style={{ color: "#333333" }} className="mg-t-5">
                    {test.title}
                </h4>
                <div style={{ color: "#5c5c5c" }}>
                    {test.description}
                </div>
                <div className='mg-t-10 mg-b-10'>
                    <span
                        onClick={() => generateTest(test)}
                        style={{
                            background: "#1b84e7",
                            borderRadius: "3px",
                            padding: "5px 10px 5px 10px",
                            cursor: "pointer",
                            color: "white",
                        }}
                    >
                        Generiši test
                    </span> {generatedTest === test.testId && <Loader style={{
                        width: "16px",
                        height: "16px",
                        borderWidth: "2px",
                    }} />}
                </div>
            </div>
        })}
        {renderGeneratedTest()}
        {saveInCollectionModal.render()}
    </div>
}
