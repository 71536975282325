import React from 'react'
import { CountryId, PossibleHigherAuthorityLevel, UserStatus } from '../utils/Common/Common'
import { DEFAULT_COUNTRY } from "../utils/ServiceLocal"

type AppContext = {
    gLoggedIn: boolean | undefined
    gSetLoggedIn: (loginStatus: boolean | undefined) => void
    gCountryId: CountryId,
    gSetCountryId: (country: CountryId) => void
    //
    //
    // logged user info:
    gMyId: string
    gSetMyId: (myId: string) => void
    gFirstname: string
    gSetFirstname: (gFirstname: string) => void
    gLastname: string
    gSetLastname: (gLastname: string) => void
    gStatus: UserStatus
    gSetStatus: (gStatus: UserStatus) => void
    // gLeadModeratorForSubjects is used for lead moderators to set notification listener for comments for those subjects
    gLeadModeratorForSubjects: string[]
    gSetLeadModeratorForSubjects: (subjectIds: string[]) => void
    gHigherAuthorityLevel: PossibleHigherAuthorityLevel | undefined
    gSetHigherAuthorityLevel: (v: PossibleHigherAuthorityLevel | undefined) => void
    //
    //
    // Notifications for logged user:
    gNotification: boolean
    gSetNotification: (notifications: boolean) => void
    gMessageNotification: boolean
    gSetMessageNotification: (friendsNotifications: boolean) => void
    // gAllCommentsNotification is only for contributors
    gAllCommentsNotification: boolean
    gSetAllCommentsNotification: (n: boolean) => void
    //
    //
    // Last checks for logged user:
    gLastMessagesCheck: number
    gSetLastMessagesCheck: (ts: number) => void
    gLastNotificationsCheck: number
    gSetLastNotificationsCheck: (ts: number) => void
    // gLastAllComentsCheck is only for contributors
    gLastAllComentsCheck: number
    gSetLastAllComentsCheck: (ts: number) => void
    //
    //
    gNDestroyedTokenIds: number
    gSetNDestroyedTokenIds: (n: number) => void
}

export const AppContext = React.createContext<AppContext>(undefined as any)

export const AppWithContext = ({ children }: { children: React.ReactNode }) => {
    const [gLoggedIn, gSetLoggedIn] = React.useState<boolean>()
    const [gCountryId, gSetCountryId] = React.useState<CountryId>(DEFAULT_COUNTRY)

    const [gMyId, gSetMyId] = React.useState('')
    const [gFirstname, gSetFirstname] = React.useState('')
    const [gLastname, gSetLastname] = React.useState('')
    const [gLeadModeratorForSubjects, gSetLeadModeratorForSubjects] = React.useState<string[]>([])
    const [gStatus, gSetStatus] = React.useState<UserStatus>('basic')

    const [gNotification, gSetNotification] = React.useState(false)
    const [gMessageNotification, gSetMessageNotification] = React.useState(false)
    const [gAllCommentsNotification, gSetAllCommentsNotification] = React.useState(false)

    const [gLastMessagesCheck, gSetLastMessagesCheck] = React.useState(9999999999)
    const [gNDestroyedTokenIds, gSetNDestroyedTokenIds] = React.useState(0)
    const [gLastNotificationsCheck, gSetLastNotificationsCheck] = React.useState(9999999999)
    const [gLastAllComentsCheck, gSetLastAllComentsCheck] = React.useState(9999999999)

    const [gHigherAuthorityLevel, gSetHigherAuthorityLevel] = React.useState<PossibleHigherAuthorityLevel>()

    return <AppContext.Provider value={{
        gStatus,
        gSetStatus,
        gMyId,
        gSetMyId,
        gFirstname,
        gSetFirstname,
        gLastname,
        gSetLastname,
        gLeadModeratorForSubjects,
        gSetLeadModeratorForSubjects,
        gHigherAuthorityLevel,
        gSetHigherAuthorityLevel,
        gLoggedIn,
        gSetLoggedIn,
        gCountryId,
        gSetCountryId,
        gNotification,
        gSetNotification,
        gMessageNotification,
        gSetMessageNotification,
        gAllCommentsNotification,
        gSetAllCommentsNotification,
        gLastMessagesCheck,
        gSetLastMessagesCheck,
        gLastNotificationsCheck,
        gSetLastNotificationsCheck,
        gLastAllComentsCheck,
        gSetLastAllComentsCheck,
        gNDestroyedTokenIds,
        gSetNDestroyedTokenIds,
    }}>
        {children}
    </AppContext.Provider>
}
