import React, { useEffect } from 'react'
import { AppContext } from './contexts/AppContext'
import { Route, Routes } from 'react-router-dom'
import { HomePage } from "./HomePage/HomePage"
import { Contribute } from './Contribute/ContributorPage'
import { AddIsbnPage } from './Contribute/AddIsbnPage'
import { SolveExercisePage } from './Contribute/SolveExercisePage'
import { MySolvingsPage } from './Contribute/MySolvingsPage'
import { ExercisePage } from './ExercisePage/ExercisePage'
import { SolveTheoryPage } from './Contribute/SolveTheoryPage'
import { TheoryPage } from './ExercisePage/TheoryPage'
import { SubjectsAuthority } from './Contribute/SubjectsAuthority'
import { SubjectAuthority } from './Contribute/SubjectAuthority'
import { SavedCollectionsPage } from './SavedCollectionsPage/SavedCollectionsPage'
import { SavedCollection } from './SavedCollectionsPage/SavedCollection'
import { check } from './utils/utilFunctions'
import { ChatsPage } from './MessagesPage/ChatsPage'
import { useNotifications } from './utils/useNotifications'
import { AllCommentsCheckPage } from './Contribute/AllCommentsCheckPage'
import { NavigateTo } from './NotLoggedIn'
import { ProfilePage } from './ProfilePage/ProfilePage'
import { PrivateClasses } from './PrivateClassesPage/PrivateClasses'
import { SubjectPrivateClasses } from './PrivateClassesPage/SubjectPrivateClasses'
import { ENVIRONMENT_CALCULATED } from './utils/constants'
import { ScanExercise } from './ScanExercisePage/ScanExercisePage'
import { EotsAndSavedCollectionsPage } from './web-view-native/EotsAndSavedCollectionsPage'
import { OptimizationsPage } from './Contribute/OptimizationsPage'
import { TrainingExercisesPage } from './Contribute/TrainingExercisesPage'
import { PricingPage } from './Pricing/PricingPage'
import { UserScanTrackerPage } from './Contribute/UserScanTrackerPage'
import { useModal } from './utils/useModal'
import { MaxNLoginsInfoModal } from './utils/MaxNLoginsInfoModal'

export const LoggedIn = () => {
  const {
    gStatus,
    gHigherAuthorityLevel,
    gLoggedIn,
    gNDestroyedTokenIds,
  } = React.useContext(AppContext)

  const maxNLoginsModal = useModal(MaxNLoginsInfoModal)

  // later maybe add more checks (for firstname, lastname, id, country etc.)
  check(gLoggedIn === true, 'ui7YU2z')

  if (ENVIRONMENT_CALCULATED === "dev") {
    // ENVIRONMENT_CALCULATED will not change
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useNotifications()
  }

  const maybeShowMaxNLoginsModal = () => {
    if (gNDestroyedTokenIds > 0) {
      maxNLoginsModal.show({})
    }
  }

  useEffect(() => {
    setTimeout(() => {
      maybeShowMaxNLoginsModal()
    }, 100)
  }, [])

  return <div className='app-background'>
    {maxNLoginsModal.render()}
    <Routes>
      <Route path="/home" element={<HomePage />} />
      <Route path="/eots-and-saved-collections" element={<EotsAndSavedCollectionsPage />} />
      <Route path="/profile/:userId" element={<ProfilePage />} />
      <Route path="/exercise/:exerciseId" element={<ExercisePage />} />
      <Route path="/theory/:theoryId" element={<TheoryPage />} />
      <Route path="/savedCollections" element={<SavedCollectionsPage />} />
      <Route path="/savedCollection/:collectionId" element={<SavedCollection />} />
      <Route path="/messages" element={<ChatsPage />} />
      <Route path="/privateClasses" element={<PrivateClasses />} />
      <Route path="/privateClasses/:subjectId" element={<SubjectPrivateClasses />} />
      {gStatus === 'leadModerator' && gHigherAuthorityLevel === 10 && <Route path="/scan-exercise" element={<ScanExercise />} />}
      {gStatus === 'leadModerator' && <Route path="/contribute" element={<Contribute />} />}
      {gStatus === 'leadModerator' && <Route path="/contribute/mySolvings" element={<MySolvingsPage />} />}
      {gStatus === 'leadModerator' && <Route path="/contribute/addIsbn" element={<AddIsbnPage />} />}
      {gStatus === 'leadModerator' && <Route path="/contribute/solveExercise" element={<SolveExercisePage />} />}
      {gStatus === 'leadModerator' && <Route path="/contribute/all-comments-check" element={<AllCommentsCheckPage />} />}
      {gStatus === 'leadModerator' && <Route path="/contribute/solveTheory" element={<SolveTheoryPage />} />}
      {gStatus === 'leadModerator' && <Route path="/contribute/scan-optimization" element={<OptimizationsPage />} />}
      {gStatus === 'leadModerator' && gHigherAuthorityLevel === 10 && <Route path="/contribute/training-exercises" element={<TrainingExercisesPage />} />}
      {gStatus === 'leadModerator' && <Route path="/contribute/subjectsAuthority" element={<SubjectsAuthority />} />}
      {gStatus === 'leadModerator' && <Route path="/contribute/subjectAuthority/:subjectId" element={<SubjectAuthority />} />}
      {gStatus === 'leadModerator' && <Route path="/pricing" element={<PricingPage />} />}
      {gStatus === 'leadModerator' && gHigherAuthorityLevel === 10 && <Route path="/contribute/user-scan-tracker" element={<UserScanTrackerPage />} />}
      <Route path="*" element={<NavigateTo to='/home' />} />
    </Routes>
  </div>
}
