import React from 'react'
import { ExerciseOrTheory, QueriedComment, TopCommentOnDetails, EotPart, VideoMetadata } from '../utils/Common/Common'
import { assertNever } from '../utils/utilFunctions'
import { renderStringWithLatex } from '../Contribute/LatexPart'
import { YoutubeVideo } from '../utils/YoutubeVideo'
import { AppContext } from '../contexts/AppContext'
import { useNavigate } from 'react-router-dom'
import { SaveInCollection_Modal } from './SaveInCollection_Modal'
import { TopCommentsWrapper } from '../TopCommentsWrapper/TopCommentsWrapper'
import { SendEntitiesModal } from './SendEntitiesModal'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'
import { useModal } from '../utils/useModal'
import { useLocalLanguage } from '../utils/useLocalLanguage'

type Props = {
    kind: ExerciseOrTheory
    eotId: string
    text_public: EotPart[]
    video_solution: VideoMetadata | null
    textSolution: EotPart[]
    subjectId: string
    initialyQueriedComments: [QueriedComment, QueriedComment[]][] | undefined // [topComment, it'sReplies][]
    hideEdit?: boolean
    hideText?: boolean
    hideSaveButton?: boolean
    row?: string
    showNeedToBuyScanPlan?: boolean
}

const alwaysFalse = Math.random() > 1

export const Eot = ({
    kind,
    eotId,
    text_public,
    video_solution,
    textSolution,
    subjectId,
    initialyQueriedComments,
    hideEdit,
    hideText,
    hideSaveButton,
    row = 'row',
    showNeedToBuyScanPlan = false,
}: Props) => {
    const [sendEntitiesModal, setSendEntitiesModal] = React.useState(false)

    const { gStatus, gLeadModeratorForSubjects } = React.useContext(AppContext)

    const navigate = useNavigate()

    const saveInCollectionModal = useModal(SaveInCollection_Modal)

    const { inLocalLanguage } = useLocalLanguage()

    const renderEotPart = (
        eotPart: EotPart,
        withComments: boolean,
        eotSolutionOrText: "eotSolution" | "eotText",
    ) => {
        const initialyQueriedCommentsForThisPart = initialyQueriedComments?.filter(it => it[0].commentInId === eotPart.id)
        const initialyQueriedCommentsForThisPartChecked = (initialyQueriedCommentsForThisPart == null || initialyQueriedCommentsForThisPart.length === 0) ? undefined : initialyQueriedCommentsForThisPart

        let topCommentOn: TopCommentOnDetails
        // it will throw error for exercise2 I guess. This will not be user ever I think.
        if (kind === 'exercise') {
            topCommentOn = {
                on: 'exercise_part',
                exerciseId: eotId,
                onId: eotPart.id,
            }
        } else if (kind === 'theory') {
            topCommentOn = {
                on: 'theory_part',
                theoryId: eotId,
                onId: eotPart.id,
            }
        } else {
            assertNever(kind)
        }

        if (eotPart.content.type === 'picture') {
            if (ENVIRONMENT_CALCULATED === "dev" && alwaysFalse && withComments) {
                return <TopCommentsWrapper
                    commentOnPreview='[photo]'
                    topCommentOn={topCommentOn}
                    key={eotPart.id}
                    subjectId={subjectId}
                    initialyQueriedComments={initialyQueriedCommentsForThisPartChecked}
                >
                    <img
                        src={eotPart.content.pictureUrl}
                        alt="No, or bad image..."
                        className='img-in-eot-solution'
                        style={{ marginBottom: '8px' }}
                    />
                </TopCommentsWrapper>
            } else {
                return <img
                    key={eotPart.id}
                    src={eotPart.content.pictureUrl}
                    alt="No, or bad image..."
                    className='img-in-eot-solution'
                    style={{ marginBottom: '8px' }}
                />
            }
        } else if (eotPart.content.type === 'text') {
            if (ENVIRONMENT_CALCULATED === "dev" && alwaysFalse && withComments) {
                return <TopCommentsWrapper
                    commentOnPreview="should be changed to comment on preview dsaiJHOIU7IUY7"
                    topCommentOn={topCommentOn}
                    key={eotPart.id}
                    subjectId={subjectId}
                    initialyQueriedComments={initialyQueriedCommentsForThisPartChecked}
                >
                    <div style={{ marginBottom: '10px' }}>
                        {renderStringWithLatex(eotPart.content.text)}
                    </div>
                </TopCommentsWrapper>
            } else {
                return <div style={{ marginBottom: '10px' }} key={eotPart.id}>
                    {renderStringWithLatex(eotPart.content.text)}
                </div>
            }
        } else {
            assertNever(eotPart.content)
        }
    }

    const renderTextSolution = () => {
        if (textSolution.length > 0) {
            return <>
                {textSolution
                    .sort((a, b) => a.order_number - b.order_number)
                    .map(it => renderEotPart(it, true, "eotSolution"))}

                {video_solution != null && <div className='after-text-separator' />}
            </>
        } else {
            return null
        }
    }

    const renderVideoSolution = () => {
        if (video_solution != null) {
            const initialyQueriedCommentsForThisPart = initialyQueriedComments?.filter(it => it[0].commentInId === video_solution.videoId)
            const initialyQueriedCommentsForThisPartChecked = (initialyQueriedCommentsForThisPart == null || initialyQueriedCommentsForThisPart.length === 0) ? undefined : initialyQueriedCommentsForThisPart

            let topCommentOn: TopCommentOnDetails
            // it will throw error for exercise2 I guess. This will not be user ever I think.
            if (kind === 'exercise') {
                topCommentOn = {
                    on: 'exercise_video',
                    exerciseId: eotId,
                    onId: video_solution.videoId,
                }
            } else if (kind === 'theory') {
                topCommentOn = {
                    on: 'theory_video',
                    theoryId: eotId,
                    onId: video_solution.videoId,
                }
            } else {
                assertNever(kind)
            }

            return <TopCommentsWrapper
                commentOnPreview="[video solution]"
                topCommentOn={topCommentOn}
                key={video_solution.videoId}
                subjectId={subjectId}
                initialyQueriedComments={initialyQueriedCommentsForThisPartChecked}
            >
                <YoutubeVideo videoId={video_solution.videoId} />
            </TopCommentsWrapper>
        } else {
            return null
        }
    }

    return <>
        <div className={`${row} exercise-page`}>
            <div className="eot-header">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {(gStatus === 'leadModerator'
                        && gLeadModeratorForSubjects.find(it => it === subjectId))
                        && !hideEdit && <div
                            className="save-eot"
                            onClick={() => {
                                let path
                                if (kind === 'exercise') {
                                    path = "/contribute/solveExercise"
                                } else if (kind === 'theory') {
                                    path = "/contribute/solveTheory"
                                } else {
                                    assertNever(kind)
                                }
                                navigate(`${path}?edit=${eotId}`)
                            }}
                        >
                            Edit/Template
                        </div>}
                    {!hideSaveButton && <div className="save-eot" onClick={() => saveInCollectionModal.show({
                        toSave: {
                            kind: "single",
                            type: kind,
                            eotId: eotId,
                        }
                    })}>
                        {inLocalLanguage("Save")} &nbsp;
                        <i className="icon ion-heart"></i>
                    </div>}
                    {ENVIRONMENT_CALCULATED === "dev" && alwaysFalse && <div className="save-eot" onClick={() => setSendEntitiesModal(true)}>
                        Send &nbsp;
                        <i className="fa fa-send"></i>
                    </div>}
                </div>
            </div>

            {!hideText && <>
                {text_public
                    .sort((a, b) => a.order_number - b.order_number)
                    .map(it => renderEotPart(it, false, "eotText"))}

                <div className='after-text-separator' />
            </>}

            {renderTextSolution()}

            {ENVIRONMENT_CALCULATED === 'dev' && renderVideoSolution()}

            {saveInCollectionModal.render()}

            {ENVIRONMENT_CALCULATED === "dev" && alwaysFalse && (kind === 'exercise' || kind === 'theory')
                && <SendEntitiesModal
                    modalIsOpen={sendEntitiesModal}
                    toggleModal={() => setSendEntitiesModal(!sendEntitiesModal)}
                    entityToSend={{
                        type: kind,
                        entityId: eotId,
                    }}
                />}
        </div>
        {showNeedToBuyScanPlan && <div style={{ textAlign: "center" }} className='mg-t-30 mg-b-40'>
            <h5 style={{ color: "red" }}>
                In order to see whole solution, you need to have active scan plan.
            </h5>
        </div>}
    </>
}
