import React, { useState } from 'react'
import { Container } from "../utils/Container"
import { calculateAmmountWithPromoCode, SCAN_PRICING_PLANS, CURRENCY_PER_COUNTRY } from '../utils/Common/Common'
import { AppContext } from '../contexts/AppContext'
import { queryServer } from '../utils/queryServer'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { assertNever } from '../utils/utilFunctions'

export const PricingPage = () => {
    const { gCountryId } = React.useContext(AppContext)

    const { currency, currencyPosition } = CURRENCY_PER_COUNTRY[gCountryId]

    const [promoCode, setPromoCode] = useState('')

    const [validatedPromoCode, setValidatedPromoCode] = useState<RequestResponseTypes['validatePromoCode']['response']>()

    const renderValidatePromoCodeMessage = () => {
        if (validatedPromoCode == null) {
            return null
        } else if (validatedPromoCode.validCode) {
            return <span style={{ color: "green" }}>
                With this promo code you have {validatedPromoCode.discountInPercent}% discount
            </span>
        } else {
            return <span style={{ color: 'red' }}>Promo code not valid</span>
        }
    }

    const amountWithCurrency = (amount: number) => {
        if (currencyPosition === 'left') {
            return `${currency} ${amount}`
        } else if (currencyPosition === 'right') {
            return `${amount} ${currency}`
        } else {
            assertNever(currencyPosition)
        }
    }

    const renderOriginalPrice = (originalPrice: number) => {
        if (validatedPromoCode?.validCode) {
            return <h3
                className='pricing-price'
                style={{ fontSize: '32px', textDecoration: 'line-through', color: 'gray' }}
            >
                {amountWithCurrency(originalPrice)}
            </h3>
        } else {
            return renderPrice(originalPrice)
        }
    }

    const renderPrice = (price: number) => {
        return <h3 className='pricing-price' style={{ fontSize: '38px' }}>
            {amountWithCurrency(price)}
        </h3>
    }

    return <Container
        title={"Pricing"}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: false,
            expandWhiteWrapperToWholeScreen: true,
        }}
    >
        <div className="row row-sm ">
            {SCAN_PRICING_PLANS[gCountryId].map(el => {
                const originalPrice = el.amount
                const withPromoCode = validatedPromoCode?.validCode === true
                    ? {
                        priceWithDiscount: calculateAmmountWithPromoCode(originalPrice, validatedPromoCode.discountInPercent),
                        promoCode: validatedPromoCode.promoCode,
                    }
                    : null

                return <div className="col-lg-4 d-flex mb-4" key={el.orderNumber}>
                    <div className="card card-pricing-two flex-fill card-equal justify-content-center">
                        <div className="bg-transparent card-header pt-4 border-0">
                            {renderOriginalPrice(el.amount)}
                            {withPromoCode != null && renderPrice(withPromoCode.priceWithDiscount)}
                        </div>
                        <ul className="pricing-list" style={{
                            fontSize: '16px',
                            color: '#3d3d3d',
                        }}>
                            <li>{el.nScansIncludedDescription}</li>
                            <li>{el.lifetimeDescription}</li>
                        </ul>
                        <BuyButton
                            planId={el.id}
                            currency={currency}
                            originalPrice={originalPrice}
                            withPromoCode={withPromoCode}
                        />
                    </div>
                </div>
            })}
        </div>
        <div>
            <input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
            />
            <span style={{ backgroundColor: 'white' }} className='span-btn' onClick={async () => {
                if (promoCode === '') {
                    return
                }

                const result = await queryServer(
                    'validatePromoCode',
                    { promoCode },
                )

                setValidatedPromoCode(result)
            }}>
                Apply promo code
            </span>
            {renderValidatePromoCodeMessage()}
        </div>
    </Container>
}

const BuyButton = ({
    planId,
    currency,
    originalPrice,
    withPromoCode,
}: {
    planId: string
    currency: string
    originalPrice: number
    withPromoCode: {
        promoCode: string
        priceWithDiscount: number
    } | null
}) => {
    const [step, setStep] = useState<'initial' | 'confirm'>('initial')
    const [message, setMessage] = useState<JSX.Element>()

    if (step === 'initial') {
        return <>
            {message}
            <button className="btn btn-primary btn-pricing" onClick={() => setStep("confirm")}>
                Kupi
            </button>
        </>
    } else {
        return <>
            <div style={{ color: "gray", fontSize: "80%" }}>
                Are you sure you want to buy this plan?
            </div>
            {message}
            <button className="btn btn-warning btn-pricing mg-b-5 mg-t-10" onClick={() => {
                setMessage(undefined)
                setStep("initial")
            }}>
                Cancel
            </button>
            <button className="btn btn-purple btn-pricing" onClick={async () => {
                const response = await queryServer(
                    "buyScanPlan",
                    {
                        planId,
                        currency,
                        originalPrice,
                        promoCodeData: withPromoCode == null ? null : {
                            priceWithDiscount: withPromoCode.priceWithDiscount,
                            promoCode: withPromoCode.promoCode,
                        }
                    },
                )

                if (response.success) {
                    setMessage(<span style={{ color: 'green' }}>You bought plan successfuly</span>)
                    setStep('initial')
                } else {
                    let msg
                    if (response.reason === 'data_for_plan_dont_match') {
                        msg = 'Problem with plan data'
                    } else if (response.reason === 'plan_doesnt_exist') {
                        msg = "Plan doesn't exist"
                    } else {
                        assertNever(response.reason)
                    }

                    setMessage(<span style={{ color: 'red' }}>{msg}</span>)
                }
            }}>
                Confirm
            </button>
        </>
    }
}
