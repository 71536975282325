import React from 'react'
import { AppContext } from '../contexts/AppContext'
import { Link } from "react-router-dom"
import { TOKEN_NAME } from '../utils/queryServer'
import { NotificationsIcon } from '../Notifications/NotificationsIcon'
import queryString from 'query-string'
import { useModal } from '../utils/useModal'
import { ChangePasswordModal } from './ChangePasswordModal'
import { ENVIRONMENT_CALCULATED } from '../utils/constants'
import { useClickOutside } from '../utils/useClickOutside'

export function MobileHeader() {
    const {
        gMyId,
        gMessageNotification,
        gStatus,
        gHigherAuthorityLevel,
    } = React.useContext(AppContext)

    const modal = useModal(ChangePasswordModal)

    const [open, setOpen] = React.useState(false)

    const ref = React.useRef<HTMLDivElement>(null)

    useClickOutside(ref, () => setOpen(false))

    return <nav className="mobile-header navbar bg-body-tertiary custom-header-wrapper" ref={ref}>
        <div className="container-fluid">
            <Link to="/home">
                <h2 className="slim-logo logo-font" style={{ fontSize: '34px' }}>
                    Lakoje
                </h2>
            </Link>
            <button className={open ? "navbar-toggler" : "navbar-toggler collapsed"} onClick={() => setOpen(!open)}>
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
            </button>
            {open && <div className={open ? 'sidenav active' : 'sidenav'}>
                <div className='sidenav-links'>
                    {gStatus === 'leadModerator' && gHigherAuthorityLevel === 10 && <div>
                        <Link to='/scan-exercise'>
                            <span className="header-notification-text" aria-expanded="true">
                                Skeniranje
                            </span>
                        </Link>
                    </div>}
                    <div>
                        <Link to={`/home`}>
                            <span className="header-notification-text" aria-expanded="true">
                                Zadaci i testovi
                            </span>
                        </Link>
                    </div>
                    {ENVIRONMENT_CALCULATED === "dev" && false && <div>
                        <Link to="/privateClasses">
                            <span className="header-notification-text" aria-expanded="true">
                                Private classes
                            </span>
                        </Link>
                    </div>}
                    {gStatus === 'leadModerator' && <div>
                        <Link to='/pricing'>
                            <span className="header-notification-text" aria-expanded="true">
                                Cene
                            </span>
                        </Link>
                    </div>}
                    {ENVIRONMENT_CALCULATED === "dev" && false && <div>
                        <Link to="/messages">
                            <span className="header-notification" aria-expanded="false">
                                <i className="icon ion-ios-chatboxes-outline"></i>
                                {gMessageNotification && <span className="indicator red"></span>}
                            </span>
                        </Link>
                    </div>}
                    <div>
                        <Link to={`/savedCollections?${queryString.stringify({ userId: gMyId })}`}>
                            <span className="header-notification-text" aria-expanded="true">
                                Sacuvane kolekcije
                            </span>
                        </Link>
                    </div>
                    {ENVIRONMENT_CALCULATED === "dev" && false && <NotificationsIcon />}
                    <div>
                        {gStatus === "leadModerator" && <Link to={`/contribute`} className="nav-link">
                            <span className="header-notification-text" aria-expanded="true">
                                Contribute
                            </span>
                        </Link>}
                    </div>
                    <div>
                        {ENVIRONMENT_CALCULATED === "dev" && <Link to={`/profile/${gMyId}`} className="nav-link">
                            <span className="header-notification-text" aria-expanded="true">
                                <i className="icon ion-ios-person" style={{ marginRight: '6px' }}></i> My profile
                            </span>
                        </Link>}
                    </div>
                    <div>
                        <span className="header-notification-text"
                            aria-expanded="true"
                            onClick={() => {
                                modal.show({})
                                setOpen(false)
                            }}
                            style={{ padding: '8px 32px 8px 14px', height: '56px' }}>
                            <i className="icon ion-ios-gear" style={{ marginRight: '6px' }}></i> Change password
                        </span>
                    </div>
                    <div>
                        <span className="header-notification-text"
                            aria-expanded="true"
                            onClick={() => {
                                localStorage.removeItem(TOKEN_NAME)
                                location.reload()
                            }}
                            style={{ padding: '8px 32px 8px 14px', height: '56px' }}>
                            <i className="icon ion-forward" style={{ marginRight: '6px' }}></i> Logout
                        </span>
                    </div>
                </div>
            </div>}
        </div>
        {modal.render()}
    </nav>
}
