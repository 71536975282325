import React from "react"
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { ModalContentProps } from "../utils/useModal"

export const MaxNLoginsInfoModal = ({
    params,
    closeModal,
}: ModalContentProps<{}>) => {
    return <div className='responsive-modal-box' style={{ width: "480px" }}>
        <ModalHeader toggle={closeModal}>
            Podsetnik
        </ModalHeader>
        <ModalBody>
            <div>
                Podsecamo te da sa jednim nalogom najvise na 2 mesta mozes biti ulogovan/a istovremeno.
            </div>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={closeModal}>OK</Button>
        </ModalFooter>
    </div>
}
