import React from "react"
import { queryServer } from "../utils/queryServer"
import { useNavigate } from 'react-router-dom'
import { RequestResponseTypes } from "../utils/Common/RequestResponseTypes"
import { EotTextInList } from "../utils/EotTextInList"

export const MyTheoryList = () => {
    const [data, setData] = React.useState<RequestResponseTypes["getSolvedTheories"]["response"]>()

    const navigate = useNavigate()

    const fetchData = async () => {
        const response = await queryServer(
            'getSolvedTheories',
            undefined,
        )
        setData(response.sort((a, b) => b.last_updated_ts - a.last_updated_ts))
    }

    React.useEffect(() => {
        fetchData()
    }, [])

    let content
    if (data == null) {
        content = <h5>Please wait</h5>
    } else if (data.length === 0) {
        content = <h5>No data</h5>
    } else {
        content = <div>
            {data.map(theory => {
                return <EotTextInList
                    eotText={theory.theory_text}
                    difficulty={undefined}
                    area={undefined}
                    exerciseOrderNumberInList={undefined}
                    avgOrderNumInBook={undefined}
                    onClick={() => navigate(`/theory/${theory.id}`)}
                    timestamp={theory.last_updated_ts}
                    key={theory.id}
                />
            })}
        </div>
    }

    return content
}