import React, { useEffect, useState } from 'react'
import { Container } from "../utils/Container"
import { queryServer } from '../utils/queryServer'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'
import { COUNTRIES, CountryId, SCAN_PRICING_PLANS } from '../utils/Common/Common'
import { nowSecondsUTC, useDateFormatters } from '../utils/utilFunctions'

type SetUserData = React.Dispatch<React.SetStateAction<{
    success: false
    email: string
} | {
    success: true
    email: string
    userId: string
    firstname: string
    lastname: string
    userCountryId: CountryId
    nNonAssignedScansUsedSinceLastAssignedBecameInvalid: number
    assignedScanTracker: null | {
        validUntilTs: number
        includedNScans: number
        usedNScans: number
        lastAssignedAtTs: number
    }
    lifetimeScanTracker: {
        nTimesAssigned: number
        nNonAssignedScansUsed: number
        nActiveScansUsed: number
    }
} | undefined>>

export const UserScanTrackerPage = () => {
    const [userEmail, setUserEmail] = useState('')
    const [userData, setUserData] = useState<RequestResponseTypes['getUserByEmail']['response']['data']>()
    const [globalScanTrackers, setGlobalScanTrackers] = useState<RequestResponseTypes['getGlobalMonthlyScanTrackers']['response']>()

    const { ddMMYYYY_HHmmss } = useDateFormatters()

    const getGlobalTracker = async () => {
        const data = await queryServer(
            'getGlobalMonthlyScanTrackers',
            undefined,
        )

        setGlobalScanTrackers(data)
    }

    useEffect(() => {
        getGlobalTracker()
    }, [])

    const renderGlobalScanTrackers = () => {
        if (globalScanTrackers == null) {
            return null
        }

        return <div>
            {globalScanTrackers.map(it => (
                <div className='mg-b-10' key={it.yearMonth}>
                    <div style={{ color: "black" }}>{it.yearMonth}</div>
                    <div>Non-assigned scans used: {it.freeScansUsed}</div>
                    <div>Assigned scans used: {it.activePlanScansUsed}</div>
                </div>
            ))}
        </div>
    }

    const renderPlan = () => {
        const assignedPlan = userData?.success === true ? userData.assignedScanTracker : null
        const nowTs = nowSecondsUTC()

        let content
        if (assignedPlan != null) {
            const isActive = assignedPlan.validUntilTs > nowTs

            content = <div>
                {isActive ? <div style={{
                    color: "green",
                    background: "#d5ffd1",
                }}>Active</div> : <div style={{
                    color: "red",
                    background: "#ffdede",
                }}>Plan has expired</div>}
                <div>Last assigned at: <span style={{ color: 'purple' }}>{ddMMYYYY_HHmmss(assignedPlan.lastAssignedAtTs)}</span></div>
                <div>Plan active to: <span style={{ color: 'purple' }}>{ddMMYYYY_HHmmss(assignedPlan.validUntilTs)}</span></div>
                <div>Included n scans: <b>{assignedPlan.includedNScans}</b></div>
                <div>Used n scans: <b>{assignedPlan.usedNScans}</b></div>
                <div>Left n scans: <b>{assignedPlan.includedNScans - assignedPlan.usedNScans}</b></div>
            </div>
        } else {
            content = <div>Doesn't have scan plan</div>
        }

        let content2
        if (userData?.success) {
            content2 = <div className='mg-t-10 mg-b-10 pd-10' style={{ background: "#fff5d9" }}>
                <div>
                    N non-assigned scans used since last assign has became
                    invalid: <b>{userData.nNonAssignedScansUsedSinceLastAssignedBecameInvalid}</b>
                </div>
                <div>
                    <div className='mg-t-10' style={{ fontWeight: "bold" }}>Lifetime:</div>
                    <div>N times assigned: {userData.lifetimeScanTracker.nTimesAssigned}</div>
                    <div>N active scans used: {userData.lifetimeScanTracker.nActiveScansUsed}</div>
                    <div>N non-active scans used: {userData.lifetimeScanTracker.nNonAssignedScansUsed}</div>
                </div>
            </div>
        }

        return <>
            <div className='mg-t-10 mg-b-10' style={{ color: "gray" }}>{ddMMYYYY_HHmmss(nowTs)}</div>
            <div className='mg-t-10 mg-b-10 pd-10' style={{ background: "#f2f2f2" }}>
                <h5>Scan plan</h5>
                {content}
            </div>
            {content2}
        </>
    }

    const renderUserData = () => {
        if (userData == null) {
            return null
        } else if (!userData.success) {
            return <p className='mg-t-20 mg-b-20'>User with email <b>{userData.email}</b> doesn't exist</p>
        } else {
            return <div style={{
                border: "1px solid lightgray",
                marginTop: "20px",
                marginBottom: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingRight: "5px",
                paddingLeft: "5px",
                color: "black",
                fontSize: "110%",
                borderRadius: "8px",
            }}>
                <div>{userData.firstname} {userData.lastname}</div>
                <div>Country: {COUNTRIES.find(c => c.id === userData.userCountryId)!.country_in_english}</div>
                <div>ID: {userData.userId}</div>
                <div>Email: {userData.email}</div>
                {renderPlan()}
                <hr />
                <PricingPlansForUser userData={userData} setUserData={setUserData} />
            </div>
        }
    }

    return <Container
        title='optimizations'
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
    >
        <div className='contributors-exercise-page'>
            {renderGlobalScanTrackers()}
            <hr />
            <input
                type="text"
                placeholder="Enter user's email address..."
                onChange={e => setUserEmail(e.target.value.trim())}
                value={userEmail}
                className='mg-b-5'
                style={{ fontSize: '16px', width: '100%' }}
            />
            <span
                className='span-btn'
                style={{ margin: 0 }}
                onClick={async () => {
                    getGlobalTracker()

                    if (userEmail === '') {
                        return
                    }

                    setUserData(undefined)

                    const data = await queryServer(
                        'getUserByEmail',
                        { email: userEmail },
                    )

                    setUserData(data.data)
                }}
            >
                Find user / Get global tracker
            </span>

            {renderUserData()}
        </div>
    </Container>
}

const PricingPlansForUser = ({
    userData,
    setUserData,
}: {
    userData: Extract<RequestResponseTypes['getUserByEmail']['response']['data'], { success: true }>
    setUserData: SetUserData
}) => {
    return <div className='row row-sm'>
        {SCAN_PRICING_PLANS[userData.userCountryId].map(p => {
            return <div className="col-lg-4 d-flex mb-4" key={p.orderNumber}>
                <div className="card card-pricing-two flex-fill card-equal justify-content-center">
                    <div className="bg-transparent card-header pt-4 border-0">
                        <h3 className='pricing-price' style={{ fontSize: '38px' }}>
                            {p.amountDescription}
                        </h3>
                    </div>
                    <ul className="pricing-list" style={{
                        fontSize: '16px',
                        color: '#3d3d3d',
                    }}>
                        <li>{p.nScansIncludedDescription}</li>
                        <li>{p.lifetimeDescription}</li>
                    </ul>
                    <BuyButton userData={userData} setUserData={setUserData} planId={p.id} />
                </div>
            </div>
        })}
    </div>
}

const BuyButton = ({
    userData,
    setUserData,
    planId,
}: {
    userData: Extract<RequestResponseTypes['getUserByEmail']['response']['data'], { success: true }>
    setUserData: SetUserData
    planId: string
}) => {
    const [step, setStep] = useState<'initial' | 'confirm'>('initial')

    if (step === 'initial') {
        return <button className="btn btn-primary btn-pricing" onClick={() => setStep("confirm")}>
            Kupi
        </button>
    } else {
        return <>
            <div style={{ color: "gray", fontSize: "80%" }}>
                Are you sure you want to add this plan to {userData.firstname} {userData.lastname}
            </div>
            <button className="btn btn-warning btn-pricing mg-b-5 mg-t-10" onClick={() => setStep("initial")}>
                Cancel
            </button>
            <button className="btn btn-purple btn-pricing" onClick={async () => {
                const data = await queryServer(
                    "assignScanPlanToUser",
                    { planId, toUserId: userData.userId },
                )

                setUserData(prev => {
                    if (prev?.email === userData.email && prev?.success) {
                        return {
                            ...prev,
                            assignedScanTracker: data.assignedScanTracker,
                            nNonAssignedScansUsedSinceLastAssignedBecameInvalid: data.nNonAssignedScansUsedSinceLastAssignedBecameInvalid,
                            lifetimeScanTracker: data.lifetimeScanTracker,
                        }
                    } else {
                        return prev
                    }
                })

                setStep('initial')
            }}>
                Confirm
            </button>
        </>
    }
}
